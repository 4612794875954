import React, { Component } from 'react';
import Loadable from 'react-loadable';
import AppRoute from 'components/AppRoute';
import { Switch, Route } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import AppLoader from 'components/AppLoader';

/*
const PublicLayout = Loadable({
  loader: () => import("layouts/PublicLayout/container"),
  loading: AppLoader,
  delay: 300,
});*/

const NoLayout = Loadable({
  loader: () => import('layouts/WrapperLayout/container'),
  loading: AppLoader,
  delay: 300,
});

const DashboardLayout = Loadable({
  loader: () => import('layouts/DashboardLayout/container'),
  loading: AppLoader,
  delay: 300,
});

const DetailsLayout = Loadable({
  loader: () => import('layouts/DetailsLayout/container'),
  loading: AppLoader,
  delay: 300,
});

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  authenticatedSelector: (state) => {
    return Boolean(state.user.id);
  },
  allowRedirectBack: false,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  //redirectAction: routerActions.replace,
  // Returns true if the user auth state is loading
  authenticatingSelector: (state) => state.user.loading,
  // Render this component when the authenticatingSelector returns true
  AuthenticatingComponent: AppLoader,
});

// const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  // redirectPath: (state, ownProps) => getHomeRoute(state.user),
  redirectPath: (state, ownProps) => '/',
  // locationHelper.getRedirectQueryParam(ownProps) || "/onboarding",
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: (state) => !Boolean(state.user.id),
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated',
  // redirectAction: routerActions.replace
});

// Load Routes for code splitting
const DashboardRoute = Loadable({
  loader: () => import('routes/Dashboard/container'),
  loading: AppLoader,
  delay: 100,
});

const ReportsRoute = Loadable({
  loader: () => import('routes/Reports/container'),
  loading: AppLoader,
  delay: 300,
});

const SalesDetailsRoute = Loadable({
  loader: () => import('routes/Reports/SalesDetails/container'),
  loading: AppLoader,
  delay: 300,
});

const InventoryDetailsRoute = Loadable({
  loader: () => import('routes/Reports/InventoryDetails/container'),
  loading: AppLoader,
  delay: 300,
});

const NotificationsRoute = Loadable({
  loader: () => import('routes/Notifications/container'),
  loading: AppLoader,
  delay: 300,
});

const InvoicesRoute = Loadable({
  loader: () => import('routes/Invoices/container'),
  loading: AppLoader,
  delay: 300,
});

const AccountRoute = Loadable({
  loader: () => import('routes/Account/container'),
  loading: AppLoader,
  delay: 300,
});

const WarehouseRoute = Loadable({
  loader: () => import('routes/Warehouse/container'),
  loading: AppLoader,
  delay: 300,
});

const TradersRoute = Loadable({
  loader: () => import('routes/Traders/container'),
  loading: AppLoader,
  delay: 300,
});

const SettingsRoute = Loadable({
  loader: () => import('routes/Settings/container'),
  loading: AppLoader,
  delay: 300,
});

const LoginRoute = Loadable({
  loader: () => import('routes/Login/container'),
  loading: AppLoader,
  delay: 300,
});

const NotFound = Loadable({
  loader: () => import('routes/NotFound/container'),
  loading: AppLoader,
  delay: 300,
});

const SuperUserRoute = Loadable({
  loader: () => import('routes/SuperUser/container'),
  loading: AppLoader,
  delay: 300,
});

// const NewClientRoute = Loadable({
//   loader: () => import("routes/NewClient/container"),
//   loading: AppLoader,
//   delay: 300
// });

// const NewTerminalRoute = Loadable({
//   loader: () => import("routes/NewTerminal/container"),
//   loading: AppLoader,
//   delay: 300
// });

class AppRoutes extends Component {
  render() {
    let rightsAdmin;
    if (this.props.user.userRights) {
      rightsAdmin = this.props.user.userRights.rightsAdmin;
    }
    // Your User rihts have been evoked. You will be logged out shortly...

    return (
      <React.Fragment>
        <Switch>
          <AppRoute
            path={'/'}
            exact
            rights={rightsAdmin}
            component={userIsAuthenticated(DashboardRoute)}
            layout={DashboardLayout}
          />

          {
            //rightsAdmin &&
            //rightsAdmin.reports.sales &&
            //rightsAdmin.reports.warehouse && (
            <AppRoute
              path={'/reports/:tabPath'}
              exact
              rights={rightsAdmin}
              component={userIsAuthenticated(ReportsRoute)}
              layout={DashboardLayout}
            />
            //)
          }

          <AppRoute
            path={'/reports/sales-details/:id/:fromDate/:toDate'}
            exact
            component={userIsAuthenticated(SalesDetailsRoute)}
            layout={DetailsLayout}
          />

          <AppRoute
            path={'/reports/inventory-details/:id'}
            exact
            component={userIsAuthenticated(InventoryDetailsRoute)}
            layout={DetailsLayout}
          />

          <AppRoute
            path={'/notifications'}
            exact
            component={userIsAuthenticated(NotificationsRoute)}
            layout={DetailsLayout}
          />

          {
            //rightsAdmin &&
            //rightsAdmin.account.shifts && (
            <AppRoute
              path={'/account/:tabPath'}
              exact
              rights={rightsAdmin}
              component={userIsAuthenticated(AccountRoute)}
              layout={DashboardLayout}
            />
            //)
          }

          {
            //rightsAdmin &&
            //rightsAdmin.warehouse.categories &&
            //rightsAdmin.warehouse.discountPolices &&
            //rightsAdmin.warehouse.products &&
            //rightsAdmin.warehouse.purchases && (
            <AppRoute
              exact
              path={'/warehouse/:tabPath'}
              rights={rightsAdmin}
              component={userIsAuthenticated(WarehouseRoute)}
              layout={DashboardLayout}
            />
            //)
          }

          {
            //rightsAdmin &&
            //rightsAdmin.sales.receipts &&
            //rightsAdmin.sales.invoices && (
            <AppRoute
              exact
              path={'/sales/:tabPath'}
              rights={rightsAdmin}
              component={userIsAuthenticated(InvoicesRoute)}
              layout={DashboardLayout}
            />
            //)
          }

          {
            //rightsAdmin &&
            //rightsAdmin.traders.customers &&
            //rightsAdmin.traders.suppliers && (
            <AppRoute
              path={'/traders/:tabPath'}
              exact
              rights={rightsAdmin}
              component={userIsAuthenticated(TradersRoute)}
              layout={DashboardLayout}
            />

            //)
          }

          {
            //rightsAdmin &&
            //rightsAdmin.settings.cashRegisterParts &&
            //rightsAdmin.settings.general &&
            //rightsAdmin.settings.tabs &&
            //rightsAdmin.settings.topup &&
            //rightsAdmin.settings.users && (
            <AppRoute
              path={'/settings/:tabPath'}
              exact
              rights={rightsAdmin}
              component={userIsAuthenticated(SettingsRoute)}
              layout={DashboardLayout}
            />
            //)
          }

          {/*
          <AppRoute
            path={"/createstore/:tabPath"}
            exact
            component={userIsAuthenticated(AdminRoute)}
            layout={DashboardLayout}
          /> */}

          <AppRoute
            path={'/superuser/:tabPath'}
            exact
            component={userIsAuthenticated(SuperUserRoute)}
            layout={DashboardLayout}
          />

          {/* <AppRoute
            path={"/createstore/:tabPath"}
            exact
            component={userIsAuthenticated(NewClientRoute)}
            layout={DashboardLayout}
          />


          <AppRoute
            path={"/createterminal/:tabPath"}
            exact
            component={userIsAuthenticated(NewTerminalRoute)}
            layout={DashboardLayout}
          /> */}

          <Route
            path={'/login'}
            exact
            component={userIsNotAuthenticated(LoginRoute)}
          />

          <AppRoute component={NotFound} layout={NoLayout} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default AppRoutes;
