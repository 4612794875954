/**
 * AppLoader
 * Shows a loader
 */

import React from "react";
import Loader from "./Loader";

export const AppLoader = ({ pastDelay }) => {
  return (
    <div className={"layout--wrapper"}>
      <Loader type={"circles"} showLoader={pastDelay} />
    </div>
  );
};

export default AppLoader;
