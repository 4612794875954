import axios from "axios";
import AuthService from "./auth";
import { apiURL } from "../utils/config";

const loadUrl = apiURL + "/store";

/**
 * Create new store
 * @param store
 * @returns {AxiosPromise}
 */
export const createStore = (store) => {
  return axios({
    url: `${loadUrl}`,
    method: "POST",
    data: { store },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Create new terminal
 * @param resource
 * @returns {AxiosPromise}
 */
export const createTerminal = (resource) => {
  return axios({
    url: `${loadUrl}/terminal`,
    method: "POST",
    data: { resource },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * AbleDisable store
 * @param resource
 * @returns {AxiosPromise}
 */
export const AbleDisableStore = (storeId, active) => {
  return axios({
    url: `${loadUrl}/${storeId}/enableDisableStore`,
    method: "PUT",
    data: {
      active,
    },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

//TODO gracePeriod
//default 15
/**
 * reNew Store
 * @param resource
 * @returns {AxiosPromise}
 */
export const reNewStore = (
  storeId,
  retailStartContractDate,
  retailEndTime,
  gracePeriod
) => {
  return axios({
    url: `${loadUrl}/${storeId}/renewStore`,
    method: "PUT",
    data: { retailStartContractDate, retailEndTime, gracePeriod },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Delete  terminal
 * @param resource
 * @returns {AxiosPromise}
 */
export const DeleteTerminals = (resource) => {
  return axios({
    url: `${loadUrl}/terminal/${resource.id}/delDeviceId`,
    method: "PUT",
    data: { resource },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Get  terminal
 * @param resource
 * @returns {AxiosPromise}
 */
export const GetTerminals = (resource) => {
  return axios({
    url: `${loadUrl}/terminal/${resource}`,
    method: "GET",
    data: { resource },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Returns a list of products (paginated)
 * @param storeId
 * @returns {AxiosPromise}
 */

export const getStore = (storeId) => {
  return axios({
    url: `${loadUrl}/${storeId}`,
    method: "GET",
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Returns a list of stores (paginated)
 * @param page
 * @param searchField
 * @param searchTerm
 * @param filter
 * @returns {AxiosPromise}
 */
export const getAllStores = (page = 0, searchField, searchTerm, filter) => {
  return axios({
    url: `${loadUrl}/allstores`,
    method: "POST",
    params: {
      page,
      searchField,
      searchTerm,
      filterName: filter,
    },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Returns a list of stores (paginated)
 * @param page
 * @param searchField
 * @param searchTerm
 * @param filter
 * @returns {AxiosPromise}
 */
export const getAllOktoStores = (
  page = 0,
  searchTerm = '',
  searchField = '',
  filter = '',
  orderCol = '', 
  orderWay  = '', 
) => {
  var data = 
    {
      "page": ""+page,
      "orderCol": orderCol,
      "orderWay": orderWay,
      "searchField": searchField,
      "searchTerm": searchTerm,
      "filterName": filter
    }
  return axios({
    url: `${loadUrl}/allOktostores`,
    method: "POST",
    data: data,
    headers: AuthService.getAuthorizationHeaders()
  });
};

/**
 * Edit the user
 * @param body
 * @returns {AxiosPromise}
 */
export const userAdmin = (body) => {
  return axios({
    url: `${loadUrl}/userAdmin`,
    method: "PUT",
    headers: AuthService.getAuthorizationHeaders(),
    data: body,
  });
};

/**
 * Update store
 * @param store
 * @returns {AxiosPromise}
 */
export const updateStore = (store) => {
  return axios({
    url: `${loadUrl}/${store.id}`,
    method: "PUT",
    data: { store },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Update storePassword
 * @param updateBody
 * @returns {AxiosPromise}
 */
export const updateStorePassword = (storeid, updateBody) => {
  return axios({
    url: `${loadUrl}/${storeid}/updateStorePassword`,
    method: "PUT",
    data: updateBody,
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Returns store users
 * @param storeId
 * @returns {AxiosPromise}
 */
export const getStoreUsers = (storeId) => {
  return axios({
    url: `${loadUrl}/${storeId}/users`,
    method: "GET",
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Returns store users
 * @param storeId
 * @param orderCol
 * @param orderWay
 * @returns {AxiosPromise}
 */
export const getStoreUsersOrder = (
  storeId,
  orderCol,
  orderWay,
  ) => {
  return axios({
    url: `${loadUrl}/${storeId}/users/${orderCol}/${orderWay}`,
    method: "GET",
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Returns store quick tabs
 * @param storeId
 * @returns {AxiosPromise}
 */
export const getStoreTabs = (storeId) => {
  return axios({
    url: `${loadUrl}/${storeId}/tabs`,
    method: "GET",
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Adds a store user
 * @param storeId
 * @param user
 * @returns {AxiosPromise}
 */
export const addStoreUser = (storeId, user) => {
  return axios({
    url: `${loadUrl}/user`,
    method: "POST",
    data: { storeId, user },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Updates a store user
 * @param storeId
 * @param user
 * @returns {AxiosPromise}
 */
export const updateStoreUser = (storeId, user) => {
  return axios({
    url: `${loadUrl}/user`,
    method: "PUT",
    data: { storeId, user },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Update a quickItem item
 * @param storeId
 * @param tabId
 * @param item
 * @returns {AxiosPromise}
 */
export const updateQuickTabItem = (storeId, tabId, item) => {
  return axios({
    url: `${loadUrl}/${storeId}/tabs/${tabId}`,
    method: "PUT",
    data: { item },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Toggle store user active state
 * @param id
 * @param active
 * @returns {AxiosPromise}
 */
export const toggleStoreUser = (id, active) => {
  return axios({
    url: `${loadUrl}/user/${id}/activate`,
    method: "PUT",
    data: {
      id,
      active,
    },
    headers: AuthService.getAuthorizationHeaders(),
  });
};

/**
 * Returns a list of stores (paginated)
 * @returns {AxiosPromise}
 */
export const getIsActiveStore = (storeId) => {
  return axios({
    url: `${loadUrl}/isStoreActive/${storeId}`,
    method: "GET",
  });
};
