let BASE_URL, ENVIRONMENT;

export function getConfig(env) {
  let configObj = {
    BASE_URL,
    ENVIRONMENT
  };

  switch (env) {
    case "development":
      configObj = {
        BASE_URL: process.env.REACT_APP_API_URL_DEV,
        ENVIRONMENT: "DEV"
      };
      break;
    case "production":
      configObj = {
        BASE_URL: process.env.REACT_APP_API_URL_PROD,
        ENVIRONMENT: "PROD"
      };
      break;
    default:
      configObj = {
        BASE_URL: process.env.REACT_APP_API_URL_DEV,
        ENVIRONMENT: "DEV"
      };
      break;
  }

  return configObj;
}


export const baseURL =
  getConfig(process.env.REACT_APP_API_ENV).BASE_URL;

export const apiURL =
  getConfig(process.env.REACT_APP_API_ENV).BASE_URL + "/api/v1";
