import React from "react";
import PropTypes from "prop-types";
import Circles from "./Types/Circles";
import Spinner from "./Types/Sprinner";
import "./loader.css";

export class Loader extends React.Component {
  /*componentWillReceiveProps (nextProps) {
    const {showLoader} = this.props;

    if (showLoader !== nextProps.showLoader) {
      this.hideScrollbars();
    }
  }

  componentDidMount () {
    this.hideScrollbars();
  }

  hideScrollbars () {
    const {showLoader, hideScrollbars} = this.props;
    const CLASS_NAME = 'body--no-overflow';
    console.log('update');
    if (hideScrollbars && showLoader) {
      document.body.class = CLASS_NAME;
    } else {
      document.body.classList.remove(CLASS_NAME);
    }
  }*/

  render() {
    const { showLoader, className, type, isLogin } = this.props;
    const cssClass = className ? className : type;
    const loaderClass = showLoader
      ? cssClass + " loader--visible"
      : cssClass + " loader--hidden";

    const renderLoaderComponent = () => {
      switch (type) {
        case "circles":
          return <Circles/>;
        case "spinner":
          return <Spinner/>;
        default:
          return <Circles/>;
      }
    };

    const loadingComponent = renderLoaderComponent();

    return (
      <div  style = {{marginLeft: isLogin ? '-10px' : ''}} className={"loader loader--" + type + " " + loaderClass}>
        {loadingComponent}
      </div>
    );
  }
}

Loader.defaultProps = {
  type: "spinner"
};

Loader.propTypes = {
  showLoader: PropTypes.bool,
  hideScrollbars: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string
};

export default Loader;
