/**
 * Process reducer
 * Handles the messages from async operations (loading, success, failure)
 */

import { createAction, handleActions } from "redux-actions";
//import { logException } from "utils/sentry-config";
//import AuthService from "services/auth";
import { toast } from "react-toastify";

// ------------------------------------
// Constants
// ------------------------------------
export const PROCESS_SET = "PROCESS_SET";
export const PROCESS_LOADING = "PROCESS_LOADING";
export const PROCESS_SUCCESS = "PROCESS_SUCCESS";
export const PROCESS_FAILURE = "PROCESS_FAILURE";
export const PROCESS_CLEAR = "PROCESS_CLEAR";

// ------------------------------------
// Actions
// ------------------------------------
const processSet = createAction(PROCESS_SET);
const processLoading = createAction(PROCESS_LOADING);
const processSuccess = createAction(PROCESS_SUCCESS);
const processFailure = createAction(PROCESS_FAILURE);
const processClear = createAction(PROCESS_CLEAR);

/**
 * logError
 * Dispatch process failure and log the exception to sentry
 * @param payload
 * @returns {function(*, *): *}
 */
/*
const logError = payload => {
  return (dispatch, getState) => {
    const { user } = getState();

    dispatch(processClear());

    if (
      payload.message &&
      typeof payload.message !== "string" &&
      payload.message.response &&
      payload.message.response.data &&
      payload.type !== "login"
    ) {
      logException("PROCESS_FAILURE", {
        TYPE: payload.type,
        ERROR: { ...payload.message },
        user: { ...user },
        ACCESS_TOKEN: { ...AuthService.authObj }
      });
    }

    return dispatch(processFailure(payload));
  };
};
*/

export const actions = {
  processSet,
  processLoading,
  processSuccess,
  processFailure,
  processClear
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  type: null,
  status: null,
  message: null
};

/**
 * getErrorMessage
 * Gets the message out of different type of errors
 * @param error
 * @returns {*|string}
 */
function getErrorMessage(error) {
  // console.log(error);
  let errorMsg = "Some error happened. Please try again later.";

  if (typeof error === "string") {
    errorMsg = error;
  } else if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  ) {
    errorMsg = error.response.data.error;
  } else {
    errorMsg =
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
        ? error.response.data.message
        : "Some error happened. Please try again later.";
  }
  // alert(errorMsg);
  return errorMsg;
}

export default handleActions(
  {
    LOGOUT_SUCCESS: state => {
      return { ...initialState };
    },
    PROCESS_SET: (state, { payload }) => {
      return {
        ...state,
        type: payload.type,
        status: payload.status,
        message: payload.message
      };
    },

    PROCESS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        status: "done",
        type: payload.type,
        message: payload.message
      };
    },

    PROCESS_LOADING: (state, { payload }) => {
      return {
        ...state,
        status: "loading",
        type: payload.type,
        message: payload.message
      };
    },

    PROCESS_FAILURE: (state, { payload }) => {
      const message = getErrorMessage(payload.message);
      toast.error(message);

      return {
        ...state,
        status: "error",
        type: payload.type,
        message: getErrorMessage(message)
      };
    },

    PROCESS_CLEAR: (state, { payload }) => {
      return {
        ...state,
        type: null,
        status: null,
        message: null
      };
    }
  },
  initialState
);
