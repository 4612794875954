/**
 * App Component
 * Entry point of the app
 * This is where the high level components like redux store, routing, error handling are initialized
 */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store, { persistor } from './store';
import WrapperLayout from './layouts/WrapperLayout/container';
import './services/interceptors';
import 'normalize.css/normalize.css';
import AppRoutes from './routes/AppRoutes/container';
import ErrorWrapper from 'components/ErrorWrapper';
import { PersistGate } from 'redux-persist/integration/react';
import 'styles/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import el from 'date-fns/locale/el';
import { IntlProvider } from 'react-intl';
import elMessages from 'locales/el';

registerLocale('el', el);
setDefaultLocale('el');

class App extends Component {
  state = { size: 'sm' };

  render() {
    return (
      <IntlProvider locale={navigator.language} messages={elMessages}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <WrapperLayout>
                <ErrorWrapper store={store}>
                  <AppRoutes />
                </ErrorWrapper>
              </WrapperLayout>
            </Router>
          </PersistGate>
        </Provider>
      </IntlProvider>
    );
  }
}

export default App;
