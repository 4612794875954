/**
 * UserService
 * Handles user related operations
 */
import { apiURL } from '../utils/config';
import AuthService from '../services/auth';
import axios from 'axios';

class UserService {
  loadUserUrl = apiURL + '/user';

  /**
   * Returns the current user
   * @returns {AxiosPromise}
   */
  getUser = () => {
    return axios({
      url: this.loadUserUrl,
      method: 'GET',
      headers: AuthService.getAuthorizationHeaders(),
    });
  };

  /**
   * Loads the user by id
   * @returns {AxiosPromise}
   */
  getUserById = (id) => {
    return axios({
      url: `${this.loadUserUrl}/${id}`,
      method: 'GET',
      headers: AuthService.getAuthorizationHeaders(),
    });
  };

  /**
   * Approve user by id
   * @param id
   * @param resource
   * @returns {AxiosPromise}
   */
  approveUser = (id, resource) => {
    return axios({
      url: `${this.loadUserUrl}/${id}/approve`,
      method: 'PUT',
      headers: AuthService.getAuthorizationHeaders(),
      data: resource,
    });
  };

  /**
   * Reject user by id
   * @param id
   * @param reason
   * @returns {AxiosPromise}
   */
  rejectUser = (id, reason) => {
    return axios({
      url: `${this.loadUserUrl}/${id}/reject`,
      method: 'PUT',
      headers: AuthService.getAuthorizationHeaders(),
      data: { reason },
    });
  };

  /**
   * Returns the hosts
   * @param term
   * @param status
   * @param page
   * @param sortField
   * @param sortBy
   * @returns {AxiosPromise}
   */
  getHosts = (term, status, page = 0, sortField, sortBy) => {
    return axios({
      url: `${this.loadUserUrl}/hosts/search`,
      method: 'GET',
      params: { term, status, page, sortField, sortBy },
      headers: AuthService.getAuthorizationHeaders(),
    });
  };

  /**
   * Returns the patients
   * @param term
   * @param page
   * @param sortField
   * @param sortBy
   * @returns {AxiosPromise}
   */
  searchPatients = (term, page = 0, sortField = 'email', sortBy = 'DESC') => {
    return axios({
      url: `${this.loadUserUrl}/patient/search`,
      method: 'GET',
      params: { term, page, sortField, sortBy },
      headers: AuthService.getAuthorizationHeaders(),
    });
  };

  /**
   * Updates the user
   * @param resource
   * @returns {AxiosPromise}
   */
  updateCurrentUser = (adminAppearance) => {
    return axios({
      url: this.loadUserUrl,
      method: 'PUT',
      headers: AuthService.getAuthorizationHeaders(),
      data: { adminAppearance },
    });
  };

  /**
   * changes the password of the user
   * @param currentPassword
   * @param password
   * @returns {AxiosPromise}
   */
  changePassword = (currentPassword, newPassword) => {
    return axios({
      url: this.loadUserUrl + '/password',
      method: 'PUT',
      headers: AuthService.getAuthorizationHeaders(),
      data: { currentPassword, newPassword },
    });
  };

  /**
   * Create new user
   * @param resource
   * @returns {AxiosPromise}
   */
  createNewUser = (resource) => {
    return axios({
      url: this.loadUserUrl,
      method: 'POST',
      headers: AuthService.getAuthorizationHeaders(),
      data: resource,
    });
  };
}

export default new UserService();
