import messages from "../locales/el";

export const MESSAGES = {
  "user:password:update": {
    done: "Password updated."
  }
};

export const ROUTES = {
  home: {
    title: messages["menuStart"],
    iconName: "home",
    pathName: "/",
    iconClassName: "icon--small icon--fill-white",
    inMenu: true,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  reports: {
    title: messages["menuReports"],
    iconName: "report",
    pathName: "/reports/sales",
    iconClassName: "icon--small icon-white",
    inMenu: true,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  salesDetails: {
    title: messages["menuSalesDetails"],
    iconName: "report",
    pathName: "/reports/sales-details",
    iconClassName: "icon--small icon-white",
    inMenu: false,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  inventoryDetails: {
    title: messages["menuInventoryDetails"],
    iconName: "report",
    pathName: "/reports/inventory-details",
    iconClassName: "icon--small icon-white",
    inMenu: false,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  notifications: {
    title: "Ειδοποιήσεις",
    iconName: "alert",
    pathName: "/notifications",
    iconClassName: "icon--small icon-white",
    inMenu: false,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  warehouse: {
    title: messages["menuWarehouse"],
    iconName: "inventory",
    pathName: "/warehouse/categories",
    iconClassName: "icon--small icon-white",
    inMenu: true,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  sales: {
    title: messages["menuSales"],
    iconName: "invoices",
    pathName: "/sales/receipts",
    iconClassName: "icon--small icon--white",
    inMenu: true,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  account: {
    title: messages["menuAccount"],
    iconName: "user",
    pathName: "/account/shifts",
    iconClassName: "icon--small icon--white",
    inMenu: true,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  traders: {
    title: messages["menuContacts"],
    iconName: "traders",
    pathName: "/traders/customers",
    iconClassName: "icon--small icon--white",
    inMenu: true,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  settings: {
    title: messages["menuSettings"],
    iconName: "settings",
    pathName: "/settings/general",
    iconClassName: "icon--small icon--white",
    inMenu: true,
    roles: ["admin", "storeAdmin", "cashier"]
  },
  superuser: {
    title: messages["admin"],
    iconName: "adduser",
    pathName: "/superuser/editcustomer",
    iconClassName: "icon--small icon--white",
    inMenu: true,
    roles: ["admin"]
  },
  forbidden: {
    title: messages["forbidden"],
    pathName: "/forbidden",
    iconClassName: "icon--small icon--white",
    inMenu: false,
    roles: ["admin"]
  }
};

export const ROLES = {
  admin: {
    name: messages["admin"]
  },
  storeAdmin: {
    name: messages["admin"]
  },
  cashier: {
    name: messages["cashier"]
  },
  NetlinkAdmin: {
    name: messages["NetlinkAdmin"]
  }
};

export const REPORT_TYPES = [
  {
    title: messages.reportStockTitle,
    name: "stock",
    id: "stock",
    label: messages.reportStock
  },
  {
    title: messages.reportLowStockTitle,
    name: "lowStock",
    id: "lowStock",
    label: messages.reportLowStock
  },
  {
    title: messages.reportInventoryTitle,
    name: "inventory",
    id: "inventory",
    label: messages.reportInventory
  }
];

export const SALES_REPORT_TYPES = [
  {
    name: "daily",
    id: "daily",
    label: messages.reportDaily
  },
  {
    name: "weekly",
    id: "weekly",
    label: messages.reportWeekly
  },
  {
    name: "monthly",
    id: "monthly",
    label: messages.reportMonthly
  }
];

export const INVOICE_TYPES = [
  {
    value: "invoice",
    name: "invoice",
    id: "invoice",
    label: messages.invoiceType
  }
];

export const INVOICE_PAYMENT_TYPES = [
  {
    value: "cash",
    name: "cash",
    id: "cash",
    label: messages.invoicePaymentCash
  },
  {
    value: "credit",
    name: "credit",
    id: "credit",
    label: messages.invoicePaymentCredit
  }
];

export const SALE_PAYMENT_TYPES = {
  CASH: {
    label: messages.salePaymentCash,
    iconName: "cash"
  },
  CARD: {
    label: messages.salePaymentCredit,
    iconName: "card"
  },
  OKTO: {
    label: messages.salePaymentOkto,
    iconName: "card"
  },
  SPLIT: {
    label: messages.salePaymentSplit,
    iconName: "wallet"
  }
};
export const SALE_PAYMENT_SELECTION_TYPES = [
  {
    label: messages.salePaymentCash,
    name: messages.salePaymentCash,
    value: "CASH",
    id: "CASH"
  },
  {
    label: messages.salePaymentCredit,
    name: messages.salePaymentCredit,
    value: "CARD",
    id: "CARD"
  },
  {
    label: messages.salePaymentSplit,
    name: messages.salePaymentSplit,
    value: "SPLIT",
    id: "SPLIT"
  },
  {
    label: messages.salePaymentOkto,
    name: messages.salePaymentOkto,
    value: "OKTO",
    id: "OKTO"
  }
];
