/**
 * Wrapper layout for the app
 * Handles the display of the app messages returned from async operations through the Process component
 * Checks if the user exists or needs to be reloaded
 */

import React from "react";
import PropTypes from "prop-types";
import AuthService from "services/auth";
import Process from "components/Process";


export class WrapperLayout extends React.Component {
  componentDidMount() {
    const { user, getUser, clearUser } = this.props;
    const isLoggedIn = AuthService.loggedIn();

    if (!user.id && isLoggedIn) {
      getUser();
    } else if (!user.id && !isLoggedIn) {
      clearUser();
    }
  }

  render() {
    return (
      <div className={"height--full"}>
        {this.props.children}
        <Process process={this.props.process} />
      </div>
    );
  }
}

WrapperLayout.propTypes = {
  children: PropTypes.node
};

export default WrapperLayout;
