/**
 * AppRoutes
 * Wrapper of the react-router Route component that allows using other components as Layouts per route
 */
import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

export const AppRoute = ({
  component: Component,
  layout: Layout,
  rights,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} rights={rights} />
        </Layout>
      )}
    />
  );
};

AppRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  layout: PropTypes.func,
};

export default AppRoute;
