/**
 * ErrorWrapper
 * Wraps the whole application, catches any errors and logs it
 */

import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

export class ErrorWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    const { store } = this.props;
    const user = store.getState().user;

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      if (user && user.id) {
        scope.setUser({ id: user.id });
      }
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.hasError ? (
          <span>Sorry some error happened. Please refresh your browser</span>
        ) : (
          this.props.children
        )}
      </React.Fragment>
    );
  }
}

export default ErrorWrapper;
