/**
 * Account reducer
 * Handles login/logout actions
 */

import { createAction, handleActions } from "redux-actions";
import { getIsActiveStore } from "../services/store";

// ------------------------------------
// Constants
// ------------------------------------
export const SUBSCRIPTION_ACTIVE = "SUBSCRIPTION_ACTIVE";

// ------------------------------------
// Actions
// ------------------------------------
const subscriptionActive = createAction(SUBSCRIPTION_ACTIVE);

const isSubscriptionActive = storeId => {
  return dispatch => {
    getIsActiveStore(storeId).then(res => {
      dispatch(subscriptionActive(res.data.activeStore));
    });
  };
};

export const actions = {
  isSubscriptionActive
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeStore: null
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return { ...initialState };
    },
    SUBSCRIPTION_ACTIVE: (state, { payload }) => {
      return {
        ...state,
        activeStore: payload
      };
    }
  },
  initialState
);
