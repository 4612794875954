import store from "../store";
import { actions as accountActions } from "modules/account";
import AuthService from "./auth";
import axios from "axios";
const clearAuth = () => {
  AuthService.clearAuthInfo();
  store.dispatch(accountActions.logout());
};

// Handle API request errors
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return new Promise((resolve, reject) => {

      console.log(">>>>> YO", error.response);
      if (error && error.response && error.response.status === 401) {
        console.log("Will clear auth");
        reject(error);
        clearAuth();
      } else {
        // Alert.error(error.response.data.message);

        if (error && error.response && error.config.clearCredentialsOnError) {
          clearAuth();
        }

        reject(error);
      }
    });
  }
);
