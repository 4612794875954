/**
 * Aborted actions reducer
 * Saves aborted menus. Starting with
 * Warehouse purchases
 */

import { createAction, handleActions } from "redux-actions";

//Used to test the tree without recieving data
//import { INVOICE_TYPES, INVOICE_PAYMENT_TYPES } from "utils/constants";

// ------------------------------------
// Constants
// ------------------------------------

export const ABORTED_PURCHASE = "ABORTED_PURCHASE";

// ------------------------------------
// Actions
// ------------------------------------

const abortedPurchase = createAction(ABORTED_PURCHASE);

const saveAbortedPurchase = (data) => {
  return (dispatch) => {
    dispatch(abortedPurchase(data));
  };
};

export const actions = {
  saveAbortedPurchase,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  abortedPurchase: null,
};

export default handleActions (
  {
    
    LOGOUT_SUCCESS: () => {
      return { ...initialState };
    },
    ABORTED_PURCHASE: (state, { payload }) => {
      return {
        ...state,
        abortedPurchase: payload,
      };
    },
  },
  initialState
);

//Export of state tree component - Repeat for every substate to abandoned in future
export const getAbortedPurchase = state => state.aborted.abortedPurchase;