export const messages = {
  login: "Είσοδος",
  email: "Email",
  password: "Password",
  shifts: "Κινήσεις Ταμείου",
  user: "Χρήστης",
  from: "Από",
  to: "Εώς",
  startAmount: "Αρχικό ποσό ταμείου",
  expectedAmount: "Αναμενόμενο ποσό",
  endAmount: "Κλείσιμο ταμείου",
  diffAmount: "Διαφορά (κλείσιμο ταμείου - αναμενόμενο ποσό)",
  deposits: "Καταθέσεις",
  withdrawals: "Αναλήψεις",
  details: "Λεπτομέρειες",
  emptyData: "Δεν υπάρχουν δεδομένα.",
  type: "Τύπος",
  createdAt: "Δημιουργήθηκε",
  amount: "Αξία",
  errorFetchingData: "Σφάλμα κατα την φόρτωση των δεδομένων",
  menuStart: "Αρχική",
  menuReports: "Αναφορές",
  menuWarehouse: "Αποθήκη",
  menuAccount: "Λογαριασμός",
  menuSales: "Πωλήσεις",
  menuSalesDetails: "Λεπτομέρειες πωλήσεων κατηγορίας",
  menuInventoryDetails: "Λεπτομέρειες καταγραφής εμπορευμάτων",
  menuContacts: "Επαφές",
  menuSettings: "Ρυθμίσεις",
  superuser: "Διαχειριστής",
  menuNewClient: "Καινούριος Πελάτης",
  menuNewTerminal: "Καινούριο Τερματικό",
  admin: "Διαχειριστής",
  cashier: "Ταμίας",
  reportStock: "Συνολικό απόθεμα",
  reportLowStock: "Χαμηλό απόθεμα",
  reportInventory: "Απογραφές",
  reportStockTitle: "Αναφορά συνολικού αποθέματος",
  reportLowStockTitle: "Αναφορά χαμηλού αποθέματος",
  reportInventoryTitle: "Αναφορά απογραφών",
  reportDaily: "Ημέρας",
  reportWeekly: "Εβδομάδας",
  reportMonthly: "Μηνιαίες",
  invoiceType: "Τιμ. Αγοράς / Δελτίο παραλαβής",
  invoicePaymentCash: "Μετρητά",
  invoicePaymentCredit: "Επί πιστώσει",
  salePaymentCash: "Μετρητά",
  salePaymentCredit: "Κάρτα",
  salePaymentOkto: "Okto.Pay",
  salePaymentSplit: "Split",
  dashboardInStock: "Προϊόντα σε απόθεμα",
  dashboardOutOfStock: "Χωρίς απόθεμα",
  dashboardLowStock: "Χαμηλό απόθεμα",
  dashboardActive: "Ενεργά",
  dashboardInactive: "Απενεργοποιημένα",
  dashboardSalesOfDay: "Πωλήσεις ημέρας",
  grossAmount: "Τζίρος",
  profitPercent: "Ποσοστό κέρδους",
  profit: "Κερδος",
  saleAverage: "Μέση απόδειξη",
  sales: "Πωλήσεις",
  reportType: "Τύπος αναφοράς",
  dashboardAmount: "Ποσό",
  topInSalesToday: "Κορυφαια σε πωλήσεις σήμερα",
  warehouse: "Αποθήκη",
  salesTabSales: "Λιανικές πωλήσεις",
  salesTabInvoices: "Τιμολόγια πωλήσεων",
  salesTabReturns: "Επιστροφές",
  customerLastName: "Επων. πελάτη",
  paymentMethod: "Τρ. πληρωμής",
  issueDateFrom: "Ημ. Από - Εώς",
  all: "Όλα",
  issueDateTo: "Ημ. Εώς",
  selectInvoiceToSeeDetails:
    " Επιλέξτε ενα τιμολόγιο απο τη λίστα δεξια για να δείτε εδώ τις λεπτομέρειες",
  reportsTabSales: "Αναφορές πωλήσεων",
  reportsTabWarehouse: "Αναφορές αποθέματος",
  productName: "Όνομα Προιόντος",
  oldStock: "Παλιό Stock",
  newStock: "Νέο Stock",
  oldPrice: "Παλιά τιμή",
  newPrice: "Νέα τιμή",
  category: "Κατηγορία",
  cost: "Κόστος",
  top10: "Top 10 προϊόντα",
  numOfSales: "Αριθμός Πωλήσεων",
  totalSales: "Συνολικές Πωλήσεις",
  inventoryTotal: "Σύνολο απογραφών",
  lastInventory: "Τελευταία απογραφή",
  activeProducts: "Ενεργά προϊόντα",
  inventoryCost: "Κόστος αποθέματος",
  startDate: "Ημ/ια έναρξης",
  endDate: "Ημ/ια λήξης",
  numOfProducts: "Αρ. προϊόντων",
  product: "Προϊόν",
  stock: "Stock",
  safetyStock: "Safety stock",
  purchasePrice: "Τιμή αγοράς",
  settingsTabStore: "Πληροφορίες καταστήματος",
  settingsTabUsers: "Χρήστες",
  settingsTabParts: "Τμήματα ταμειακής",
  settingsTabTopup: "Top up",
  settingsTabQuickTabs: "Καρτέλες προϊόντων",
  successPartCreation: "Το τμήμα δημιουργήθηκε.",
  successPartUpdate: "Το τμήμα αποθηκεύτηκε.",
  addPartTitle: "Νέο τμήμα ταμειακής",
  editPartTitle: "Επεξεργασία τμήματος ταμειακής",
  addPart: "Προσθήκη τμήματος",
  partName: "Όνομασία τμήματος",
  partNumber: "Αριθμός τμήματος",
  choose: "Επιλογή...",
  create: "Δημιουργία",
  save: "Αποθήκευση",
  name: "Ονομασία",
  number: "Αριθμός",
  decimals: "Number of decimal points",
  generalInfo: "Γενικές πληροφορίες",
  vatState: "Καθεστώς ΦΠΑ",
  decimalsTitle: "Δεκαδικά ψηφία",
  storeInfo: "Ονομασία καταστήματος",
  vat: "ΑΦΜ",
  storeType: "Τύπος καταστήματος",
  financialAuthority: "ΔΟΥ",
  address: "Διεύθυνση",
  county: "Νομός",
  postCode: "ΤΚ",
  region: "Περιφέρεια",
  city: "Πόλη",
  tel: "Τηλέφωνο",
  tel1: "Τηλέφωνο 1",
  tel2: "Τηλέφωνο 2",
  email1: "Email 1",
  email2: "Email 2",
  activation: "Ενεργοποίηση",
  end: "Λήξη"
};

export default messages;
