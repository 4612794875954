/**
 * User reducer
 * Handles user actions
 */

import { createAction, handleActions } from "redux-actions";
import { REPORT_TYPES } from "utils/constants";
import { startOfMonth, endOfDay } from "date-fns";

// ------------------------------------
// Constants
// ------------------------------------
export const REPORT_SET_DATE_FROM = "REPORT_SET_DATE_FROM";
export const REPORT_SET_DATE_TO = "REPORT_SET_DATE_TO";
export const REPORT_SET_WAREHOUSE_SELECTED_TYPE =
  "REPORT_SET_WAREHOUSE_SELECTED_TYPE";

// ------------------------------------
// Actions
// ------------------------------------
const reportSetDayFrom = createAction(REPORT_SET_DATE_FROM);
const reportSetDayTo = createAction(REPORT_SET_DATE_TO);
const reportSetWarehouseSelectedType = createAction(
  REPORT_SET_WAREHOUSE_SELECTED_TYPE
);

export const actions = {
  reportSetDayFrom,
  reportSetDayTo,
  reportSetWarehouseSelectedType
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  dateFrom: startOfMonth(new Date()).getTime(),
  dateTo: endOfDay(new Date()).getTime(),
  warehouseSelectedType: REPORT_TYPES[0]
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return { ...initialState };
    },
    REPORT_SET_DATE_FROM: (state, { payload }) => {
      return { ...state, dateFrom: Number(payload) };
    },

    REPORT_SET_DATE_TO: (state, { payload }) => {
      return { ...state, dateTo: Number(payload) };
    },

    REPORT_SET_WAREHOUSE_SELECTED_TYPE: (state, { payload }) => {
      return { ...state, warehouseSelectedType: payload };
    }
  },
  initialState
);
