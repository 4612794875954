import React from 'react';

export const Circles = () => {
  return (
    <div className='loader__container'>
      <div className='loader__double-bounce1' />
      <div className='loader__double-bounce2' />
    </div>
  );
};

export default Circles;
