/**
 * User reducer
 * Handles user actions
 */

import { createAction, handleActions } from 'redux-actions';
import UserService from '../services/user';
import { actions as processActions } from '../modules/process';

// ------------------------------------
// Constants
// ------------------------------------
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_CLEAR = 'USER_CLEAR';

// ------------------------------------
// Actions
// ------------------------------------
const userSuccess = createAction(USER_SUCCESS);
const userError = createAction(USER_ERROR);
//const userUpdateSuccess = createAction(USER_UPDATE_SUCCESS);
const clearUser = createAction(USER_CLEAR);

// types
const TYPE_USER_LOAD = 'user:load';
const TYPE_USER_UPDATE = 'user:update';
const TYPE_USER_AVATAR_UPLOAD = 'user:avatar:upload';

/**
 * Load user
 * @returns {function(*)}
 */
const getUser = () => {
  return (dispatch) => {
    processActions.processLoading({
      type: TYPE_USER_LOAD,
    });

    UserService.getUser().then(
      (response) => {
        processActions.processSuccess({
          type: TYPE_USER_LOAD,
        });
        return dispatch(userSuccess(response.data));
      },
      (error) => {
        dispatch(userError());
        return dispatch(
          processActions.processFailure({
            type: TYPE_USER_LOAD,
            message: error,
          }),
        );
      },
    );
  };
};

/**
 * Update user
 * @param updateObj
 * @returns {Function}
 */
const updateUser = (updateObj) => {
  return (dispatch) => {
    const processType = updateObj.hasOwnProperty('avatarBase64')
      ? TYPE_USER_AVATAR_UPLOAD
      : TYPE_USER_UPDATE;

    dispatch(
      processActions.processLoading({
        type: processType,
      }),
    );

    UserService.updateCurrentUser(updateObj).then(
      (response) => {
        /*
        dispatch(
          processActions.processSuccess({
            type: processType,
          }),
        );
        return dispatch(userUpdateSuccess(response.data));
        */
      },
      (error) => {
        /*
        return dispatch(
          processActions.processFailure({
            type: processType,
            message: error,
          }),
        );
        */
      },
    );
  };
};

export const actions = {
  getUser,
  updateUser,
  userSuccess,
  clearUser,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: true,
  lang: 'el',
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return { ...initialState, loading: false };
    },
    USER_SUCCESS: (state, { payload }) => {
      return { ...state, ...payload, loading: false };
    },

    USER_ERROR: (state) => {
      return { ...state, loading: false };
    },

    USER_UPDATE_SUCCESS: (state, { payload }) => {
      return { ...payload, loading: false };
    },

    USER_CLEAR: () => {
      return { ...initialState, loading: false };
    },
  },
  initialState,
);
