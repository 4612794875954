/**
 * Nav reducer
 * Handles nav actions
 */

import { createAction, handleActions } from "redux-actions";

// ------------------------------------
// Constants
// ------------------------------------
export const NAV_SET_INFO = "NAV_SET_INFO";

// ------------------------------------
// Actions
// ------------------------------------
const navSetInfo = createAction(NAV_SET_INFO);

export const actions = {
  navSetInfo
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  title: null,
  iconName: null
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return { ...initialState };
    },
    NAV_SET_INFO: (state, { payload }) => {
      return { ...state, ...payload };
    }
  },
  initialState
);
