/**
 * AuthService
 * Handles authentication
 */

import { apiURL } from "utils/config";
import CookieHelper from "utils/cookie";
import axios from "axios";

class AuthService {
  loginUrl = apiURL + "/auth/login";

  authObj = {
    isLoggedIn: false,
    token: null
  };

  requestsQueue = [];

  defaultHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  loginHeaders = {
    Accept: "application/json",
    "Content-Type": undefined
  };

  loggedIn = (authInfo = CookieHelper.load("auth")) => {
    let isLoggedIn = false;
    if (authInfo) {
      isLoggedIn = JSON.parse(authInfo).isLoggedIn;
    }
    return isLoggedIn;
  };

  login = (username, password) => {
    return axios({
      url: this.loginUrl,
      method: "POST",
      data: { username, password },
      headers: this.loginHeaders
    });
  };

  setAuthInfo = authObj => {
    authObj.isLoggedIn = true;
    this.authObj = authObj;
    CookieHelper.save("auth", JSON.stringify(authObj));
  };

  clearAuthInfo = () => {
    this.authObj = {};
    this.roles = [];
    CookieHelper.remove("auth");
    CookieHelper.remove("roles");
  };

  getAuthorizationHeaders = (authInfo = CookieHelper.load("auth")) => {
    let authHeaderValue = null;

    if (authInfo) {
      authHeaderValue = JSON.parse(authInfo).token;
    }

    return Object.assign({}, this.getDefaultHeaders(), {
      Authorization: "Bearer " + authHeaderValue
    });
  };

  getDefaultHeaders = () => {
    return this.defaultHeaders;
  };
}

export default new AuthService();
