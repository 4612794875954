/**
 * Account reducer
 * Handles login/logout actions
 */

import { createAction, handleActions } from "redux-actions";
import AuthService from "../services/auth";
import { getIsActiveStore } from "../services/store";
import { actions as processActions } from "../modules/process";
import { actions as userActions } from "../modules/user";
import { toast } from "react-toastify";
import { AbleDisableStore } from "../services/store";

// ------------------------------------
// Constants
// ------------------------------------
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// ------------------------------------
// Actions
// ------------------------------------
const loginLoading = createAction(LOGIN_LOADING);
const loginSuccess = createAction(LOGIN_SUCCESS);
const logoutSuccess = createAction(LOGOUT_SUCCESS);
const loginFailure = createAction(LOGIN_FAILURE);

/**
 * Login
 * Login and dispatch user load
 * @param username
 * @param password
 * @returns {function(*): *}
 */
const login = (username, password) => {
  return dispatch => {
    dispatch(loginLoading());

    dispatch(
      processActions.processLoading({
        type: "login"
      })
    );

    return AuthService.login(username, password).then(
      response => {
        getIsActiveStore(response.data.user.storeId).then(res => {
          let endRetail = new Date(res.data.activeStore.retailEndTime);
          endRetail = endRetail.getTime();
          let endRetailwithGrace =
            endRetail +
            res.data.activeStore.retailGracePeriodInDays * 24 * 60 * 60 * 1000;
          let today = new Date(Date.now());
          if (res.data.activeStore.active) {
            if (endRetail > today) {
              AuthService.setAuthInfo({
                token: response.data.token
              });

              dispatch(userActions.userSuccess(response.data.user));
              dispatch(loginSuccess(response.data));

              dispatch(
                processActions.processSuccess({
                  type: "login"
                })
              );
            } else if (endRetailwithGrace > today) {
              let difference_ms = endRetailwithGrace - today;
              //take out milliseconds
              difference_ms = difference_ms / 1000;
              difference_ms = difference_ms / 60;
              difference_ms = difference_ms / 60;
              let days = Math.floor(difference_ms / 24);
              toast.warn(
                `Η συνδρομή σας στο Okto.Retail έληξε. Έχετε επιπλέον ${
                  days === 0 || days === 1
                    ? 1 + " ημέρα χρήσης."
                    : days + " ημέρες χρήσης."
                } `
              );

              AuthService.setAuthInfo({
                token: response.data.token
              });

              dispatch(userActions.userSuccess(response.data.user));
              dispatch(loginSuccess(response.data));

              dispatch(
                processActions.processSuccess({
                  type: "login"
                })
              );
            } else {
              dispatch(userActions.clearUser());
              AuthService.clearAuthInfo();
              AbleDisableStore(response.data.user.storeId, false);
              dispatch(
                processActions.processFailure({
                  type: "login",
                  message:
                    "Η συνδρομή σας στο Okto.Retail έληξε. Παρακαλώ επικοινωνήστε μαζί μας για να ανανεώσετε."
                })
              );
              return dispatch(loginFailure);
            }
          } else {
            dispatch(userActions.clearUser());
            AuthService.clearAuthInfo();
            dispatch(
              processActions.processFailure({
                type: "login",
                message:
                  "Η συνδρομή σας στο Okto.Retail έληξε. Παρακαλώ επικοινωνήστε μαζί μας για να ανανεώσετε."
              })
            );
            return dispatch(loginFailure);
          }
        });
      },
      err => {
        console.log("err", err.response);
        dispatch(
          processActions.processFailure({
            type: "login",
            message: err
          })
        );
        return dispatch(loginFailure);
      }
    );
  };
};

/**
 * Logout
 * @returns {function(*): *}
 */
const logout = () => {
  return dispatch => {
    dispatch(loginLoading(true));
    dispatch(userActions.clearUser());
    AuthService.clearAuthInfo();
    return dispatch(logoutSuccess());
  };
};

export const actions = {
  login,
  logout,
  loginLoading,
  loginFailure,
  logoutSuccess,
  loginSuccess
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isLoggedIn: false,
  loading: false,
  errors: null,
  data: {}
};

export default handleActions(
  {
    LOGOUT_SUCCESS: state => {
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        errors: null,
        data: {}
      };
    },

    LOGIN_LOADING: state => {
      return {
        ...state,
        loading: !state.loading,
        errors: null
      };
    },

    LOGIN_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        data: payload,
        errors: null
      };
    },

    LOGIN_FAILURE: (state, { payload }) => {
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
        errors: payload
      };
    }
  },
  initialState
);
